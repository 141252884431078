import { FilterSetGroup } from "src/app/blades/explore/models/filter.model";
import {
  CreateLayerSetConfig,
  DeckLayerTypes,
  LayerTypes
} from "src/app/blades/explore/models/layer.model";
import { TableColumnsObject, TooltipColumn } from "src/app/blades/explore/models/poi-table.model";
import { layerColors, MonitoringConfig, PlatformConfig } from "./config";
import { UserRole, UserRoleMapping } from "src/app/blades/users/models/basic.model";
import { DashboardUrl } from "src/app/shared/models/global-layout.model";

const defaultTable: TableColumnsObject = {
  tableType: "all",
  columns: [
    { name: "ID", key: null, prop: "id", isSortable: true, isString: false },
    { name: "Name", key: null, prop: "name", isSortable: false, isString: true },
    { name: "Address", key: null, prop: "address", isSortable: false, isString: true },
    { name: "Merchant Type", key: null, prop: "merchant_type", isSortable: false, isString: true },
    {
      name: "Merchant Status",
      key: null,
      prop: "merchant_status",
      isSortable: false,
      isString: true
    }
  ]
};

const gridWfslFS: FilterSetGroup[] = [
  {
    name: "Business Insights",
    prop: "businessInsights",
    filters: [
      // "DS_potential_category",
      // "DS_potential_score",
      "business_potential_value"
    ]
  },
  {
    name: "Demographics",
    prop: "demographicsIndex",
    filters: [
      "HIG_%",
      "LIG_%",
      "MIG_%",
      "affluence",
      "commercial",
      "educational",
      "entertainment",
      "financial",
      "footfall",
      "income",
      "income_group",
      "medical",
      "population",
      "transit_%",
      "hospital_and_clinics_hub_%",
      "local_market_%"
    ]
  },
  {
    name: "Amenities",
    prop: "amenitiesIndex",
    filters: ["no_of_brands", "no_of_hospitals", "organized_pharmacy", "local_pharmacy"]
  },
  {
    name: "Amenities Density",
    prop: "amenitiesDensityIndex",
    filters: ["merchant_density", "chemist_density", "doctor_density"]
  },
  {
    name: "Amenities Distance",
    prop: "amenitiesDistanceIndex",
    filters: [
      "distance_from_discounted_pharmacy",
      "distance_from_local_pharmacy",
      "distance_from_organised_pharmacy",
      "distance_from_supermarket"
    ]
  },
  {
    name: "Ids",
    prop: "ids",
    filters: ["cluster_ID"]
  }
];

export const defaultFS: FilterSetGroup[] = [...gridWfslFS];

const doctorFS: FilterSetGroup[] = [
  {
    name: "Doctor Properties",
    prop: "props",
    filters: [
      "action",
      "cipla_efforts",
      "cipla_engagement",
      "cipla_prescription",
      "core_SL_tagging",
      "doctor_potential",
      "doctor_type",
      "education_qualification",
      "location_type",
      "speciality",
      "years_of_experience"
    ]
  }
];

const doctorTable: TableColumnsObject = {
  tableType: "all",
  columns: [
    { name: "Name", key: null, prop: "name", isSortable: false, isString: true },
    { name: "Address", key: null, prop: "address", isSortable: false, isString: true },
    { name: "Latitude", key: null, prop: "latitude", isSortable: true, isString: false },
    { name: "Longitude", key: null, prop: "longitude", isSortable: false, isString: false },
    {
      name: "Cipla Efforts",
      key: "internalDetails",
      prop: "cipla_efforts",
      isSortable: false,
      isString: true
    },
    {
      name: "Cipla Engagement",
      key: "internalDetails",
      prop: "cipla_engagement",
      isSortable: false,
      isString: true
    },
    {
      name: "Cipla Prescription",
      key: "internalDetails",
      prop: "cipla_prescription",
      isSortable: false,
      isString: true
    },
    {
      name: "Core SL Tagging",
      key: "internalDetails",
      prop: "core_SL_tagging",
      isSortable: false,
      isString: true
    },
    {
      name: "Doctor Potential",
      key: "internalDetails",
      prop: "doctor_potential",
      isSortable: false,
      isString: true
    },
    {
      name: "Education Qualification",
      key: "internalDetails",
      prop: "education_qualification",
      isSortable: false,
      isString: true
    },
    {
      name: "Legacy Code",
      key: "internalDetails",
      prop: "legacy_code",
      isSortable: false,
      isString: true
    },
    {
      name: "Location Type",
      key: "internalDetails",
      prop: "location_type",
      isSortable: false,
      isString: true
    },
    {
      name: "MDM ID",
      key: "internalDetails",
      prop: "MDM_ID",
      isSortable: false,
      isString: true
    },
    {
      name: "Merchant Type",
      key: "internalDetails",
      prop: "merchant_type",
      isSortable: false,
      isString: true
    },
    {
      name: "Speciality",
      key: "internalDetails",
      prop: "speciality",
      isSortable: false,
      isString: true
    },
    {
      name: "Years Of Experience",
      key: "internalDetails",
      prop: "years_of_experience",
      isSortable: true,
      isString: false
    }
  ]
};

const doctorTooltipColumns: TooltipColumn[] = [
  {
    name: "Name",
    key: null,
    prop: "name"
  },
  {
    name: "Location Type",
    key: "internalDetails",
    prop: "location_type"
  },
  {
    name: "Speciality",
    key: "internalDetails",
    prop: "speciality"
  },
  {
    name: "Taskforce",
    key: "internalDetails",
    prop: "taskforce"
  },
  {
    name: "Core/SL Tagging",
    key: "internalDetails",
    prop: "core_SL_tagging"
  },
  {
    name: "Address",
    key: null,
    prop: "address"
  },
  {
    name: "Years of Experience",
    key: "internalDetails",
    prop: "years_of_experience"
  },
  {
    name: "Qualification",
    key: "internalDetails",
    prop: "education_qualification"
  }
];

const chemistFS: FilterSetGroup[] = [
  {
    name: "Chemist Properties",
    prop: "props",
    filters: ["chemist_potential_category"]
  }
];

const chemistTable: TableColumnsObject = {
  tableType: "all",
  columns: [
    { name: "ID", key: null, prop: "id", isSortable: true, isString: false },
    { name: "Name", key: null, prop: "name", isSortable: false, isString: true },
    { name: "Address", key: null, prop: "address", isSortable: false, isString: true },
    { name: "Latitude", key: null, prop: "latitude", isSortable: true, isString: false },
    { name: "Longitude", key: null, prop: "longitude", isSortable: false, isString: false },
    {
      name: "Chemist Potential Category",
      key: "internalDetails",
      prop: "chemist_potential_category",
      isSortable: false,
      isString: true
    }
  ]
};

const arcTable: TableColumnsObject = {
  tableType: "all",
  columns: [
    { name: "Name", key: null, prop: "name", isSortable: false, isString: true },
    {
      name: "Doctor",
      key: "internalDetails",
      prop: "Doctor_Name",
      isSortable: false,
      isString: true
    },
    {
      name: "Chemist",
      key: "internalDetails",
      prop: "Chemist_Name",
      isSortable: false,
      isString: true
    },
    {
      name: "Arc Weight",
      key: "internalDetails",
      prop: "Arc_Weight",
      isSortable: false,
      isString: true
    }
  ]
};

const layerSetsConfig: CreateLayerSetConfig[] = [
  {
    name: "Geographies",
    addString: "Add Geography(s)",
    layers: [
      {
        name: "City",
        deckLayerType: DeckLayerTypes.POLYGON,
        layerType: LayerTypes.SHAPE,
        layerColor: layerColors[3],
        dataUrl: "/shape/levelsearch-new",
        prop: ["City"],
        filterSetGroups: [],
        barredDeepDiveAttributes: []
      }
    ]
  },
  {
    name: "My Layers",
    addString: "View My Layers",
    layers: [
      {
        name: "Chemist",
        deckLayerType: DeckLayerTypes.POINTS,
        layerType: LayerTypes.TARGET,
        layerColor: layerColors[1],
        dataUrl: "/poi-shape/filter",
        prop: ["Chemist"],
        filterSetGroups: chemistFS,
        tableColumns: chemistTable,
        barredDeepDiveAttributes: [],
        geographyAreaThreshold: 1000000
      },
      {
        name: "Doctors",
        deckLayerType: DeckLayerTypes.POINTS,
        layerType: LayerTypes.TARGET,
        layerColor: layerColors[7],
        dataUrl: "/poi-shape/filter",
        prop: ["Doctor"],
        tableColumns: doctorTable,
        filterSetGroups: doctorFS,
        barredDeepDiveAttributes: [],
        geographyAreaThreshold: 100000,
        tooltipConfig: {
          title: "Doctor",
          defaultColumns: doctorTooltipColumns
        }
      },
      {
        name: "Doctor -> Chemist",
        deckLayerType: DeckLayerTypes.POLYGON,
        layerType: LayerTypes.SHAPE,
        layerColor: layerColors[2],
        dataUrl: "/shape/levelsearch-new",
        prop: ["Arc"],
        tableColumns: arcTable,
        filterSetGroups: [
          {
            name: "Internal Properties",
            prop: "internalProps",
            filters: ["arc_weight"]
          },
          ...doctorFS,
          ...chemistFS
        ],
        barredDeepDiveAttributes: [],
        geographyAreaThreshold: 1000000,
        tooltipConfig: {
          title: "Doctor -> Chemist",
          defaultColumns: [
            { name: "Doctor", key: "internalDetails", prop: "doctor_name" },
            { name: "Chemist", key: "internalDetails", prop: "chemist_name" }
          ]
        }
      }
    ]
  },
  {
    name: "Business Units",
    addString: "Add Business Unit(s)",
    layers: [
      {
        name: "Cresta",
        deckLayerType: DeckLayerTypes.POINTS,
        layerType: LayerTypes.TARGET,
        layerColor: layerColors[20],
        dataUrl: "/poi-shape/filter",
        prop: ["Doctor"],
        tableColumns: doctorTable,
        filterSetGroups: doctorFS,
        barredDeepDiveAttributes: [],
        geographyAreaThreshold: 10000,
        defaultFilter: {
          poiDetails: [
            {
              key: "business_unit_Cresta",
              dataType: "string",
              values: ["True"]
            }
          ]
        },
        tooltipConfig: {
          title: " Cresta",
          defaultColumns: doctorTooltipColumns
        }
      },
      {
        name: "Critical Care",
        deckLayerType: DeckLayerTypes.POINTS,
        layerType: LayerTypes.TARGET,
        layerColor: layerColors[1],
        dataUrl: "/poi-shape/filter",
        prop: ["Doctor"],
        tableColumns: doctorTable,
        filterSetGroups: doctorFS,
        barredDeepDiveAttributes: [],
        geographyAreaThreshold: 10000,
        defaultFilter: {
          poiDetails: [
            {
              key: "business_unit_Critical_Care",
              dataType: "string",
              values: ["True"]
            }
          ]
        },
        tooltipConfig: {
          title: " Critical Care",
          defaultColumns: doctorTooltipColumns
        }
      },
      {
        name: "FEGO",
        deckLayerType: DeckLayerTypes.POINTS,
        layerType: LayerTypes.TARGET,
        layerColor: layerColors[2],
        dataUrl: "/poi-shape/filter",
        prop: ["Doctor"],
        tableColumns: doctorTable,
        filterSetGroups: doctorFS,
        barredDeepDiveAttributes: [],
        geographyAreaThreshold: 10000,
        defaultFilter: {
          poiDetails: [
            {
              key: "business_unit_FEGO",
              dataType: "string",
              values: ["True"]
            }
          ]
        },
        tooltipConfig: {
          title: " FEGO",
          defaultColumns: doctorTooltipColumns
        }
      },
      {
        name: "Foresight",
        deckLayerType: DeckLayerTypes.POINTS,
        layerType: LayerTypes.TARGET,
        layerColor: layerColors[3],
        dataUrl: "/poi-shape/filter",
        prop: ["Doctor"],
        tableColumns: doctorTable,
        filterSetGroups: doctorFS,
        barredDeepDiveAttributes: [],
        geographyAreaThreshold: 10000,
        defaultFilter: {
          poiDetails: [
            {
              key: "business_unit_Foresight",
              dataType: "string",
              values: ["True"]
            }
          ]
        },
        tooltipConfig: {
          title: " Foresight",
          defaultColumns: doctorTooltipColumns
        }
      },
      {
        name: "Horizon",
        deckLayerType: DeckLayerTypes.POINTS,
        layerType: LayerTypes.TARGET,
        layerColor: layerColors[4],
        dataUrl: "/poi-shape/filter",
        prop: ["Doctor"],
        tableColumns: doctorTable,
        filterSetGroups: doctorFS,
        barredDeepDiveAttributes: [],
        geographyAreaThreshold: 10000,
        defaultFilter: {
          poiDetails: [
            {
              key: "business_unit_Horizon",
              dataType: "string",
              values: ["True"]
            }
          ]
        },
        tooltipConfig: {
          title: " Horizon",
          defaultColumns: doctorTooltipColumns
        }
      },
      {
        name: "Immunology",
        deckLayerType: DeckLayerTypes.POINTS,
        layerType: LayerTypes.TARGET,
        layerColor: layerColors[5],
        dataUrl: "/poi-shape/filter",
        prop: ["Doctor"],
        tableColumns: doctorTable,
        filterSetGroups: doctorFS,
        barredDeepDiveAttributes: [],
        geographyAreaThreshold: 10000,
        defaultFilter: {
          poiDetails: [
            {
              key: "business_unit_Immunology",
              dataType: "string",
              values: ["True"]
            }
          ]
        },
        tooltipConfig: {
          title: " Immunology",
          defaultColumns: doctorTooltipColumns
        }
      },
      {
        name: "Impulse",
        deckLayerType: DeckLayerTypes.POINTS,
        layerType: LayerTypes.TARGET,
        layerColor: layerColors[6],
        dataUrl: "/poi-shape/filter",
        prop: ["Doctor"],
        tableColumns: doctorTable,
        filterSetGroups: doctorFS,
        barredDeepDiveAttributes: [],
        geographyAreaThreshold: 10000,
        defaultFilter: {
          poiDetails: [
            {
              key: "business_unit_Impulse",
              dataType: "string",
              values: ["True"]
            }
          ]
        },
        tooltipConfig: {
          title: " Impulse",
          defaultColumns: doctorTooltipColumns
        }
      },
      {
        name: "Inspira",
        deckLayerType: DeckLayerTypes.POINTS,
        layerType: LayerTypes.TARGET,
        layerColor: layerColors[7],
        dataUrl: "/poi-shape/filter",
        prop: ["Doctor"],
        tableColumns: doctorTable,
        filterSetGroups: doctorFS,
        barredDeepDiveAttributes: [],
        geographyAreaThreshold: 10000,
        defaultFilter: {
          poiDetails: [
            {
              key: "business_unit_Inspira",
              dataType: "string",
              values: ["True"]
            }
          ]
        },
        tooltipConfig: {
          title: " Inspira",
          defaultColumns: doctorTooltipColumns
        }
      },
      {
        name: "Key Accounts and Public Health",
        deckLayerType: DeckLayerTypes.POINTS,
        layerType: LayerTypes.TARGET,
        layerColor: layerColors[8],
        dataUrl: "/poi-shape/filter",
        prop: ["Doctor"],
        tableColumns: doctorTable,
        filterSetGroups: doctorFS,
        barredDeepDiveAttributes: [],
        geographyAreaThreshold: 10000,
        defaultFilter: {
          poiDetails: [
            {
              key: "business_unit_Key_Accounts_and_Public_Health",
              dataType: "string",
              values: ["True"]
            }
          ]
        },
        tooltipConfig: {
          title: " Key Accounts and Public Health",
          defaultColumns: doctorTooltipColumns
        }
      },
      {
        name: "Nurture",
        deckLayerType: DeckLayerTypes.POINTS,
        layerType: LayerTypes.TARGET,
        layerColor: layerColors[9],
        dataUrl: "/poi-shape/filter",
        prop: ["Doctor"],
        tableColumns: doctorTable,
        filterSetGroups: doctorFS,
        barredDeepDiveAttributes: [],
        geographyAreaThreshold: 10000,
        defaultFilter: {
          poiDetails: [
            {
              key: "business_unit_Nurture",
              dataType: "string",
              values: ["True"]
            }
          ]
        },
        tooltipConfig: {
          title: " Nurture",
          defaultColumns: doctorTooltipColumns
        }
      },
      {
        name: "Oncology",
        deckLayerType: DeckLayerTypes.POINTS,
        layerType: LayerTypes.TARGET,
        layerColor: layerColors[10],
        dataUrl: "/poi-shape/filter",
        prop: ["Doctor"],
        tableColumns: doctorTable,
        filterSetGroups: doctorFS,
        barredDeepDiveAttributes: [],
        geographyAreaThreshold: 10000,
        defaultFilter: {
          poiDetails: [
            {
              key: "business_unit_Oncology",
              dataType: "string",
              values: ["True"]
            }
          ]
        },
        tooltipConfig: {
          title: " Oncology",
          defaultColumns: doctorTooltipColumns
        }
      },
      {
        name: "OSTEON",
        deckLayerType: DeckLayerTypes.POINTS,
        layerType: LayerTypes.TARGET,
        layerColor: layerColors[11],
        dataUrl: "/poi-shape/filter",
        prop: ["Doctor"],
        tableColumns: doctorTable,
        filterSetGroups: doctorFS,
        barredDeepDiveAttributes: [],
        geographyAreaThreshold: 10000,
        defaultFilter: {
          poiDetails: [
            {
              key: "business_unit_OSTEON",
              dataType: "string",
              values: ["True"]
            }
          ]
        },
        tooltipConfig: {
          title: " OSTEON",
          defaultColumns: doctorTooltipColumns
        }
      },
      {
        name: "Respiratory",
        deckLayerType: DeckLayerTypes.POINTS,
        layerType: LayerTypes.TARGET,
        layerColor: layerColors[12],
        dataUrl: "/poi-shape/filter",
        prop: ["Doctor"],
        tableColumns: doctorTable,
        filterSetGroups: doctorFS,
        barredDeepDiveAttributes: [],
        geographyAreaThreshold: 10000,
        defaultFilter: {
          poiDetails: [
            {
              key: "business_unit_Respiratory",
              dataType: "string",
              values: ["True"]
            }
          ]
        },
        tooltipConfig: {
          title: " Respiratory",
          defaultColumns: doctorTooltipColumns
        }
      },
      {
        name: "RTASP",
        deckLayerType: DeckLayerTypes.POINTS,
        layerType: LayerTypes.TARGET,
        layerColor: layerColors[13],
        dataUrl: "/poi-shape/filter",
        prop: ["Doctor"],
        tableColumns: doctorTable,
        filterSetGroups: doctorFS,
        barredDeepDiveAttributes: [],
        geographyAreaThreshold: 10000,
        defaultFilter: {
          poiDetails: [
            {
              key: "business_unit_RTASP",
              dataType: "string",
              values: ["True"]
            }
          ]
        },
        tooltipConfig: {
          title: " RTASP",
          defaultColumns: doctorTooltipColumns
        }
      },
      {
        name: "Supracare",
        deckLayerType: DeckLayerTypes.POINTS,
        layerType: LayerTypes.TARGET,
        layerColor: layerColors[14],
        dataUrl: "/poi-shape/filter",
        prop: ["Doctor"],
        tableColumns: doctorTable,
        filterSetGroups: doctorFS,
        barredDeepDiveAttributes: [],
        geographyAreaThreshold: 10000,
        defaultFilter: {
          poiDetails: [
            {
              key: "business_unit_Supracare",
              dataType: "string",
              values: ["True"]
            }
          ]
        },
        tooltipConfig: {
          title: " Supracare",
          defaultColumns: doctorTooltipColumns
        }
      },
      {
        name: "Urology",
        deckLayerType: DeckLayerTypes.POINTS,
        layerType: LayerTypes.TARGET,
        layerColor: layerColors[15],
        dataUrl: "/poi-shape/filter",
        prop: ["Doctor"],
        tableColumns: doctorTable,
        filterSetGroups: doctorFS,
        barredDeepDiveAttributes: [],
        geographyAreaThreshold: 10000,
        defaultFilter: {
          poiDetails: [
            {
              key: "business_unit_Urology",
              dataType: "string",
              values: ["True"]
            }
          ]
        },
        tooltipConfig: {
          title: " Urology",
          defaultColumns: doctorTooltipColumns
        }
      },
      {
        name: "Virology",
        deckLayerType: DeckLayerTypes.POINTS,
        layerType: LayerTypes.TARGET,
        layerColor: layerColors[16],
        dataUrl: "/poi-shape/filter",
        prop: ["Doctor"],
        tableColumns: doctorTable,
        filterSetGroups: doctorFS,
        barredDeepDiveAttributes: [],
        geographyAreaThreshold: 10000,
        defaultFilter: {
          poiDetails: [
            {
              key: "business_unit_Virology",
              dataType: "string",
              values: ["True"]
            }
          ]
        },
        tooltipConfig: {
          title: " Virology",
          defaultColumns: doctorTooltipColumns
        }
      },
      {
        name: "Vitalis",
        deckLayerType: DeckLayerTypes.POINTS,
        layerType: LayerTypes.TARGET,
        layerColor: layerColors[17],
        dataUrl: "/poi-shape/filter",
        prop: ["Doctor"],
        tableColumns: doctorTable,
        filterSetGroups: doctorFS,
        barredDeepDiveAttributes: [],
        geographyAreaThreshold: 10000,
        defaultFilter: {
          poiDetails: [
            {
              key: "business_unit_Vitalis",
              dataType: "string",
              values: ["True"]
            }
          ]
        },
        tooltipConfig: {
          title: " Vitalis",
          defaultColumns: doctorTooltipColumns
        }
      },
      {
        name: "WOMENS HEALTH",
        deckLayerType: DeckLayerTypes.POINTS,
        layerType: LayerTypes.TARGET,
        layerColor: layerColors[18],
        dataUrl: "/poi-shape/filter",
        prop: ["Doctor"],
        tableColumns: doctorTable,
        filterSetGroups: doctorFS,
        barredDeepDiveAttributes: [],
        geographyAreaThreshold: 10000,
        defaultFilter: {
          poiDetails: [
            {
              key: "business_unit_WOMENS_HEALTH",
              dataType: "string",
              values: ["True"]
            }
          ]
        },
        tooltipConfig: {
          title: " WOMENS HEALTH",
          defaultColumns: doctorTooltipColumns
        }
      },
      {
        name: "Xterna",
        deckLayerType: DeckLayerTypes.POINTS,
        layerType: LayerTypes.TARGET,
        layerColor: layerColors[19],
        dataUrl: "/poi-shape/filter",
        prop: ["Doctor"],
        tableColumns: doctorTable,
        filterSetGroups: doctorFS,
        barredDeepDiveAttributes: [],
        geographyAreaThreshold: 10000,
        defaultFilter: {
          poiDetails: [
            {
              key: "business_unit_Xterna",
              dataType: "string",
              values: ["True"]
            }
          ]
        },
        tooltipConfig: {
          title: " Xterna",
          defaultColumns: doctorTooltipColumns
        }
      },
      {
        name: "Zesta",
        deckLayerType: DeckLayerTypes.POINTS,
        layerType: LayerTypes.TARGET,
        layerColor: layerColors[20],
        dataUrl: "/poi-shape/filter",
        prop: ["Doctor"],
        tableColumns: doctorTable,
        filterSetGroups: doctorFS,
        barredDeepDiveAttributes: [],
        geographyAreaThreshold: 10000,
        defaultFilter: {
          poiDetails: [
            {
              key: "business_unit_Zesta",
              dataType: "string",
              values: ["True"]
            }
          ]
        },
        tooltipConfig: {
          title: " Zesta",
          defaultColumns: doctorTooltipColumns
        }
      },
      {
        name: "Zion",
        deckLayerType: DeckLayerTypes.POINTS,
        layerType: LayerTypes.TARGET,
        layerColor: layerColors[21],
        dataUrl: "/poi-shape/filter",
        prop: ["Doctor"],
        tableColumns: doctorTable,
        filterSetGroups: doctorFS,
        barredDeepDiveAttributes: [],
        geographyAreaThreshold: 10000,
        defaultFilter: {
          poiDetails: [
            {
              key: "business_unit_Zion",
              dataType: "string",
              values: ["True"]
            }
          ]
        },
        tooltipConfig: {
          title: " Zion",
          defaultColumns: doctorTooltipColumns
        }
      }
    ]
  },
  {
    name: "Taks Forces",
    addString: "Add Task Force(s)",
    layers: [
      {
        name: "Alliance",
        deckLayerType: DeckLayerTypes.POINTS,
        layerType: LayerTypes.TARGET,
        layerColor: layerColors[20],
        dataUrl: "/poi-shape/filter",
        prop: ["Doctor"],
        tableColumns: doctorTable,
        filterSetGroups: doctorFS,
        barredDeepDiveAttributes: [],
        geographyAreaThreshold: 10000,
        defaultFilter: {
          poiDetails: [
            {
              key: "taskforce_Alliance",
              dataType: "string",
              values: ["True"]
            }
          ]
        },
        tooltipConfig: {
          title: "Alliance",
          defaultColumns: doctorTooltipColumns
        }
      },
      {
        name: "Basaglar",
        deckLayerType: DeckLayerTypes.POINTS,
        layerType: LayerTypes.TARGET,
        layerColor: layerColors[1],
        dataUrl: "/poi-shape/filter",
        prop: ["Doctor"],
        tableColumns: doctorTable,
        filterSetGroups: doctorFS,
        barredDeepDiveAttributes: [],
        geographyAreaThreshold: 10000,
        defaultFilter: {
          poiDetails: [
            {
              key: "taskforce_Basaglar",
              dataType: "string",
              values: ["True"]
            }
          ]
        },
        tooltipConfig: {
          title: "Basaglar",
          defaultColumns: doctorTooltipColumns
        }
      },
      {
        name: "Cluster 1",
        deckLayerType: DeckLayerTypes.POINTS,
        layerType: LayerTypes.TARGET,
        layerColor: layerColors[2],
        dataUrl: "/poi-shape/filter",
        prop: ["Doctor"],
        tableColumns: doctorTable,
        filterSetGroups: doctorFS,
        barredDeepDiveAttributes: [],
        geographyAreaThreshold: 10000,
        defaultFilter: {
          poiDetails: [
            {
              key: "taskforce_Cluster_1",
              dataType: "string",
              values: ["True"]
            }
          ]
        },
        tooltipConfig: {
          title: "Cluster 1",
          defaultColumns: doctorTooltipColumns
        }
      },
      {
        name: "Cluster 2",
        deckLayerType: DeckLayerTypes.POINTS,
        layerType: LayerTypes.TARGET,
        layerColor: layerColors[3],
        dataUrl: "/poi-shape/filter",
        prop: ["Doctor"],
        tableColumns: doctorTable,
        filterSetGroups: doctorFS,
        barredDeepDiveAttributes: [],
        geographyAreaThreshold: 10000,
        defaultFilter: {
          poiDetails: [
            {
              key: "taskforce_Cluster_2",
              dataType: "string",
              values: ["True"]
            }
          ]
        },
        tooltipConfig: {
          title: "Cluster 2",
          defaultColumns: doctorTooltipColumns
        }
      },
      {
        name: "COPD",
        deckLayerType: DeckLayerTypes.POINTS,
        layerType: LayerTypes.TARGET,
        layerColor: layerColors[4],
        dataUrl: "/poi-shape/filter",
        prop: ["Doctor"],
        tableColumns: doctorTable,
        filterSetGroups: doctorFS,
        barredDeepDiveAttributes: [],
        geographyAreaThreshold: 10000,
        defaultFilter: {
          poiDetails: [
            {
              key: "taskforce_COPD",
              dataType: "string",
              values: ["True"]
            }
          ]
        },
        tooltipConfig: {
          title: "COPD",
          defaultColumns: doctorTooltipColumns
        }
      },
      {
        name: "Cresta",
        deckLayerType: DeckLayerTypes.POINTS,
        layerType: LayerTypes.TARGET,
        layerColor: layerColors[5],
        dataUrl: "/poi-shape/filter",
        prop: ["Doctor"],
        tableColumns: doctorTable,
        filterSetGroups: doctorFS,
        barredDeepDiveAttributes: [],
        geographyAreaThreshold: 10000,
        defaultFilter: {
          poiDetails: [
            {
              key: "taskforce_Cresta",
              dataType: "string",
              values: ["True"]
            }
          ]
        },
        tooltipConfig: {
          title: "Cresta",
          defaultColumns: doctorTooltipColumns
        }
      },
      {
        name: "Diagnostic",
        deckLayerType: DeckLayerTypes.POINTS,
        layerType: LayerTypes.TARGET,
        layerColor: layerColors[6],
        dataUrl: "/poi-shape/filter",
        prop: ["Doctor"],
        tableColumns: doctorTable,
        filterSetGroups: doctorFS,
        barredDeepDiveAttributes: [],
        geographyAreaThreshold: 10000,
        defaultFilter: {
          poiDetails: [
            {
              key: "taskforce_Diagnostic",
              dataType: "string",
              values: ["True"]
            }
          ]
        },
        tooltipConfig: {
          title: "Diagnostic",
          defaultColumns: doctorTooltipColumns
        }
      },
      {
        name: "FC",
        deckLayerType: DeckLayerTypes.POINTS,
        layerType: LayerTypes.TARGET,
        layerColor: layerColors[7],
        dataUrl: "/poi-shape/filter",
        prop: ["Doctor"],
        tableColumns: doctorTable,
        filterSetGroups: doctorFS,
        barredDeepDiveAttributes: [],
        geographyAreaThreshold: 10000,
        defaultFilter: {
          poiDetails: [
            {
              key: "taskforce_FC",
              dataType: "string",
              values: ["True"]
            }
          ]
        },
        tooltipConfig: {
          title: "FC",
          defaultColumns: doctorTooltipColumns
        }
      },
      {
        name: "FEGO",
        deckLayerType: DeckLayerTypes.POINTS,
        layerType: LayerTypes.TARGET,
        layerColor: layerColors[8],
        dataUrl: "/poi-shape/filter",
        prop: ["Doctor"],
        tableColumns: doctorTable,
        filterSetGroups: doctorFS,
        barredDeepDiveAttributes: [],
        geographyAreaThreshold: 10000,
        defaultFilter: {
          poiDetails: [
            {
              key: "taskforce_FEGO",
              dataType: "string",
              values: ["True"]
            }
          ]
        },
        tooltipConfig: {
          title: "FEGO",
          defaultColumns: doctorTooltipColumns
        }
      },
      {
        name: "Foresight 1",
        deckLayerType: DeckLayerTypes.POINTS,
        layerType: LayerTypes.TARGET,
        layerColor: layerColors[9],
        dataUrl: "/poi-shape/filter",
        prop: ["Doctor"],
        tableColumns: doctorTable,
        filterSetGroups: doctorFS,
        barredDeepDiveAttributes: [],
        geographyAreaThreshold: 10000,
        defaultFilter: {
          poiDetails: [
            {
              key: "taskforce_Foresight_1",
              dataType: "string",
              values: ["True"]
            }
          ]
        },
        tooltipConfig: {
          title: "Foresight 1",
          defaultColumns: doctorTooltipColumns
        }
      },
      {
        name: "Foresight 2",
        deckLayerType: DeckLayerTypes.POINTS,
        layerType: LayerTypes.TARGET,
        layerColor: layerColors[10],
        dataUrl: "/poi-shape/filter",
        prop: ["Doctor"],
        tableColumns: doctorTable,
        filterSetGroups: doctorFS,
        barredDeepDiveAttributes: [],
        geographyAreaThreshold: 10000,
        defaultFilter: {
          poiDetails: [
            {
              key: "taskforce_Foresight_2",
              dataType: "string",
              values: ["True"]
            }
          ]
        },
        tooltipConfig: {
          title: "Foresight 2",
          defaultColumns: doctorTooltipColumns
        }
      },
      {
        name: "Horizon 1",
        deckLayerType: DeckLayerTypes.POINTS,
        layerType: LayerTypes.TARGET,
        layerColor: layerColors[11],
        dataUrl: "/poi-shape/filter",
        prop: ["Doctor"],
        tableColumns: doctorTable,
        filterSetGroups: doctorFS,
        barredDeepDiveAttributes: [],
        geographyAreaThreshold: 10000,
        defaultFilter: {
          poiDetails: [
            {
              key: "taskforce_Horizon_1",
              dataType: "string",
              values: ["True"]
            }
          ]
        },
        tooltipConfig: {
          title: "Horizon 1",
          defaultColumns: doctorTooltipColumns
        }
      },
      {
        name: "Horizon 2",
        deckLayerType: DeckLayerTypes.POINTS,
        layerType: LayerTypes.TARGET,
        layerColor: layerColors[12],
        dataUrl: "/poi-shape/filter",
        prop: ["Doctor"],
        tableColumns: doctorTable,
        filterSetGroups: doctorFS,
        barredDeepDiveAttributes: [],
        geographyAreaThreshold: 10000,
        defaultFilter: {
          poiDetails: [
            {
              key: "taskforce_Horizon_2",
              dataType: "string",
              values: ["True"]
            }
          ]
        },
        tooltipConfig: {
          title: "Horizon 2",
          defaultColumns: doctorTooltipColumns
        }
      },
      {
        name: "Horizon Hybrid",
        deckLayerType: DeckLayerTypes.POINTS,
        layerType: LayerTypes.TARGET,
        layerColor: layerColors[13],
        dataUrl: "/poi-shape/filter",
        prop: ["Doctor"],
        tableColumns: doctorTable,
        filterSetGroups: doctorFS,
        barredDeepDiveAttributes: [],
        geographyAreaThreshold: 10000,
        defaultFilter: {
          poiDetails: [
            {
              key: "taskforce_Horizon_Hybrid",
              dataType: "string",
              values: ["True"]
            }
          ]
        },
        tooltipConfig: {
          title: "Horizon Hybrid",
          defaultColumns: doctorTooltipColumns
        }
      },
      {
        name: "Impulse",
        deckLayerType: DeckLayerTypes.POINTS,
        layerType: LayerTypes.TARGET,
        layerColor: layerColors[14],
        dataUrl: "/poi-shape/filter",
        prop: ["Doctor"],
        tableColumns: doctorTable,
        filterSetGroups: doctorFS,
        barredDeepDiveAttributes: [],
        geographyAreaThreshold: 10000,
        defaultFilter: {
          poiDetails: [
            {
              key: "taskforce_Impulse",
              dataType: "string",
              values: ["True"]
            }
          ]
        },
        tooltipConfig: {
          title: "Impulse",
          defaultColumns: doctorTooltipColumns
        }
      },
      {
        name: "Impulse 1",
        deckLayerType: DeckLayerTypes.POINTS,
        layerType: LayerTypes.TARGET,
        layerColor: layerColors[15],
        dataUrl: "/poi-shape/filter",
        prop: ["Doctor"],
        tableColumns: doctorTable,
        filterSetGroups: doctorFS,
        barredDeepDiveAttributes: [],
        geographyAreaThreshold: 10000,
        defaultFilter: {
          poiDetails: [
            {
              key: "taskforce_Impulse_1",
              dataType: "string",
              values: ["True"]
            }
          ]
        },
        tooltipConfig: {
          title: "Impulse 1",
          defaultColumns: doctorTooltipColumns
        }
      },
      {
        name: "Impulse 2",
        deckLayerType: DeckLayerTypes.POINTS,
        layerType: LayerTypes.TARGET,
        layerColor: layerColors[16],
        dataUrl: "/poi-shape/filter",
        prop: ["Doctor"],
        tableColumns: doctorTable,
        filterSetGroups: doctorFS,
        barredDeepDiveAttributes: [],
        geographyAreaThreshold: 10000,
        defaultFilter: {
          poiDetails: [
            {
              key: "taskforce_Impulse_2",
              dataType: "string",
              values: ["True"]
            }
          ]
        },
        tooltipConfig: {
          title: "Impulse 2",
          defaultColumns: doctorTooltipColumns
        }
      },
      {
        name: "Impulse Hybrid",
        deckLayerType: DeckLayerTypes.POINTS,
        layerType: LayerTypes.TARGET,
        layerColor: layerColors[17],
        dataUrl: "/poi-shape/filter",
        prop: ["Doctor"],
        tableColumns: doctorTable,
        filterSetGroups: doctorFS,
        barredDeepDiveAttributes: [],
        geographyAreaThreshold: 10000,
        defaultFilter: {
          poiDetails: [
            {
              key: "taskforce_Impulse_Hybrid",
              dataType: "string",
              values: ["True"]
            }
          ]
        },
        tooltipConfig: {
          title: "Impulse Hybrid",
          defaultColumns: doctorTooltipColumns
        }
      },
      {
        name: "Inspira",
        deckLayerType: DeckLayerTypes.POINTS,
        layerType: LayerTypes.TARGET,
        layerColor: layerColors[18],
        dataUrl: "/poi-shape/filter",
        prop: ["Doctor"],
        tableColumns: doctorTable,
        filterSetGroups: doctorFS,
        barredDeepDiveAttributes: [],
        geographyAreaThreshold: 10000,
        defaultFilter: {
          poiDetails: [
            {
              key: "taskforce_Inspira",
              dataType: "string",
              values: ["True"]
            }
          ]
        },
        tooltipConfig: {
          title: "Inspira",
          defaultColumns: doctorTooltipColumns
        }
      },
      {
        name: "Institution",
        deckLayerType: DeckLayerTypes.POINTS,
        layerType: LayerTypes.TARGET,
        layerColor: layerColors[19],
        dataUrl: "/poi-shape/filter",
        prop: ["Doctor"],
        tableColumns: doctorTable,
        filterSetGroups: doctorFS,
        barredDeepDiveAttributes: [],
        geographyAreaThreshold: 10000,
        defaultFilter: {
          poiDetails: [
            {
              key: "taskforce_Institution",
              dataType: "string",
              values: ["True"]
            }
          ]
        },
        tooltipConfig: {
          title: "Institution",
          defaultColumns: doctorTooltipColumns
        }
      },
      {
        name: "Metabolics MAXX",
        deckLayerType: DeckLayerTypes.POINTS,
        layerType: LayerTypes.TARGET,
        layerColor: layerColors[20],
        dataUrl: "/poi-shape/filter",
        prop: ["Doctor"],
        tableColumns: doctorTable,
        filterSetGroups: doctorFS,
        barredDeepDiveAttributes: [],
        geographyAreaThreshold: 10000,
        defaultFilter: {
          poiDetails: [
            {
              key: "taskforce_Metabolics_MAXX",
              dataType: "string",
              values: ["True"]
            }
          ]
        },
        tooltipConfig: {
          title: "Metabolics MAXX",
          defaultColumns: doctorTooltipColumns
        }
      },
      {
        name: "Metabolic Taskforce",
        deckLayerType: DeckLayerTypes.POINTS,
        layerType: LayerTypes.TARGET,
        layerColor: layerColors[21],
        dataUrl: "/poi-shape/filter",
        prop: ["Doctor"],
        tableColumns: doctorTable,
        filterSetGroups: doctorFS,
        barredDeepDiveAttributes: [],
        geographyAreaThreshold: 10000,
        defaultFilter: {
          poiDetails: [
            {
              key: "taskforce_Metabolic_Taskforce",
              dataType: "string",
              values: ["True"]
            }
          ]
        },
        tooltipConfig: {
          title: "Metabolic Taskforce",
          defaultColumns: doctorTooltipColumns
        }
      },
      {
        name: "Neb 1",
        deckLayerType: DeckLayerTypes.POINTS,
        layerType: LayerTypes.TARGET,
        layerColor: layerColors[22],
        dataUrl: "/poi-shape/filter",
        prop: ["Doctor"],
        tableColumns: doctorTable,
        filterSetGroups: doctorFS,
        barredDeepDiveAttributes: [],
        geographyAreaThreshold: 10000,
        defaultFilter: {
          poiDetails: [
            {
              key: "taskforce_Neb_1",
              dataType: "string",
              values: ["True"]
            }
          ]
        },
        tooltipConfig: {
          title: "Neb 1",
          defaultColumns: doctorTooltipColumns
        }
      },
      {
        name: "Neb 2",
        deckLayerType: DeckLayerTypes.POINTS,
        layerType: LayerTypes.TARGET,
        layerColor: layerColors[23],
        dataUrl: "/poi-shape/filter",
        prop: ["Doctor"],
        tableColumns: doctorTable,
        filterSetGroups: doctorFS,
        barredDeepDiveAttributes: [],
        geographyAreaThreshold: 10000,
        defaultFilter: {
          poiDetails: [
            {
              key: "taskforce_Neb_2",
              dataType: "string",
              values: ["True"]
            }
          ]
        },
        tooltipConfig: {
          title: "Neb 2",
          defaultColumns: doctorTooltipColumns
        }
      },
      {
        name: "Neb - Arise",
        deckLayerType: DeckLayerTypes.POINTS,
        layerType: LayerTypes.TARGET,
        layerColor: layerColors[24],
        dataUrl: "/poi-shape/filter",
        prop: ["Doctor"],
        tableColumns: doctorTable,
        filterSetGroups: doctorFS,
        barredDeepDiveAttributes: [],
        geographyAreaThreshold: 10000,
        defaultFilter: {
          poiDetails: [
            {
              key: "taskforce_Neb_-_Arise",
              dataType: "string",
              values: ["True"]
            }
          ]
        },
        tooltipConfig: {
          title: "Neb - Arise",
          defaultColumns: doctorTooltipColumns
        }
      },
      {
        name: "Nurture",
        deckLayerType: DeckLayerTypes.POINTS,
        layerType: LayerTypes.TARGET,
        layerColor: layerColors[25],
        dataUrl: "/poi-shape/filter",
        prop: ["Doctor"],
        tableColumns: doctorTable,
        filterSetGroups: doctorFS,
        barredDeepDiveAttributes: [],
        geographyAreaThreshold: 10000,
        defaultFilter: {
          poiDetails: [
            {
              key: "taskforce_Nurture",
              dataType: "string",
              values: ["True"]
            }
          ]
        },
        tooltipConfig: {
          title: "Nurture",
          defaultColumns: doctorTooltipColumns
        }
      },
      {
        name: "Oncology Team 1",
        deckLayerType: DeckLayerTypes.POINTS,
        layerType: LayerTypes.TARGET,
        layerColor: layerColors[26],
        dataUrl: "/poi-shape/filter",
        prop: ["Doctor"],
        tableColumns: doctorTable,
        filterSetGroups: doctorFS,
        barredDeepDiveAttributes: [],
        geographyAreaThreshold: 10000,
        defaultFilter: {
          poiDetails: [
            {
              key: "taskforce_Oncology_Team_1",
              dataType: "string",
              values: ["True"]
            }
          ]
        },
        tooltipConfig: {
          title: "Oncology Team 1",
          defaultColumns: doctorTooltipColumns
        }
      },
      {
        name: "Oncology Team 2",
        deckLayerType: DeckLayerTypes.POINTS,
        layerType: LayerTypes.TARGET,
        layerColor: layerColors[27],
        dataUrl: "/poi-shape/filter",
        prop: ["Doctor"],
        tableColumns: doctorTable,
        filterSetGroups: doctorFS,
        barredDeepDiveAttributes: [],
        geographyAreaThreshold: 10000,
        defaultFilter: {
          poiDetails: [
            {
              key: "taskforce_Oncology_Team_2",
              dataType: "string",
              values: ["True"]
            }
          ]
        },
        tooltipConfig: {
          title: "Oncology Team 2",
          defaultColumns: doctorTooltipColumns
        }
      },
      {
        name: "Oncology Team 3",
        deckLayerType: DeckLayerTypes.POINTS,
        layerType: LayerTypes.TARGET,
        layerColor: layerColors[28],
        dataUrl: "/poi-shape/filter",
        prop: ["Doctor"],
        tableColumns: doctorTable,
        filterSetGroups: doctorFS,
        barredDeepDiveAttributes: [],
        geographyAreaThreshold: 10000,
        defaultFilter: {
          poiDetails: [
            {
              key: "taskforce_Oncology_Team_3",
              dataType: "string",
              values: ["True"]
            }
          ]
        },
        tooltipConfig: {
          title: "Oncology Team 3",
          defaultColumns: doctorTooltipColumns
        }
      },
      {
        name: "Oncology Team Hybrid",
        deckLayerType: DeckLayerTypes.POINTS,
        layerType: LayerTypes.TARGET,
        layerColor: layerColors[29],
        dataUrl: "/poi-shape/filter",
        prop: ["Doctor"],
        tableColumns: doctorTable,
        filterSetGroups: doctorFS,
        barredDeepDiveAttributes: [],
        geographyAreaThreshold: 10000,
        defaultFilter: {
          poiDetails: [
            {
              key: "taskforce_Oncology_Team_Hybrid",
              dataType: "string",
              values: ["True"]
            }
          ]
        },
        tooltipConfig: {
          title: "Oncology Team Hybrid",
          defaultColumns: doctorTooltipColumns
        }
      },
      {
        name: "OSTEON",
        deckLayerType: DeckLayerTypes.POINTS,
        layerType: LayerTypes.TARGET,
        layerColor: layerColors[30],
        dataUrl: "/poi-shape/filter",
        prop: ["Doctor"],
        tableColumns: doctorTable,
        filterSetGroups: doctorFS,
        barredDeepDiveAttributes: [],
        geographyAreaThreshold: 10000,
        defaultFilter: {
          poiDetails: [
            {
              key: "taskforce_OSTEON",
              dataType: "string",
              values: ["True"]
            }
          ]
        },
        tooltipConfig: {
          title: "OSTEON",
          defaultColumns: doctorTooltipColumns
        }
      },
      {
        name: "PH Care",
        deckLayerType: DeckLayerTypes.POINTS,
        layerType: LayerTypes.TARGET,
        layerColor: layerColors[31],
        dataUrl: "/poi-shape/filter",
        prop: ["Doctor"],
        tableColumns: doctorTable,
        filterSetGroups: doctorFS,
        barredDeepDiveAttributes: [],
        geographyAreaThreshold: 10000,
        defaultFilter: {
          poiDetails: [
            {
              key: "taskforce_PH_Care",
              dataType: "string",
              values: ["True"]
            }
          ]
        },
        tooltipConfig: {
          title: "PH Care",
          defaultColumns: doctorTooltipColumns
        }
      },
      {
        name: "Respiratory 1",
        deckLayerType: DeckLayerTypes.POINTS,
        layerType: LayerTypes.TARGET,
        layerColor: layerColors[32],
        dataUrl: "/poi-shape/filter",
        prop: ["Doctor"],
        tableColumns: doctorTable,
        filterSetGroups: doctorFS,
        barredDeepDiveAttributes: [],
        geographyAreaThreshold: 10000,
        defaultFilter: {
          poiDetails: [
            {
              key: "taskforce_Respiratory_1",
              dataType: "string",
              values: ["True"]
            }
          ]
        },
        tooltipConfig: {
          title: "Respiratory 1",
          defaultColumns: doctorTooltipColumns
        }
      },
      {
        name: "Respiratory 2",
        deckLayerType: DeckLayerTypes.POINTS,
        layerType: LayerTypes.TARGET,
        layerColor: layerColors[33],
        dataUrl: "/poi-shape/filter",
        prop: ["Doctor"],
        tableColumns: doctorTable,
        filterSetGroups: doctorFS,
        barredDeepDiveAttributes: [],
        geographyAreaThreshold: 10000,
        defaultFilter: {
          poiDetails: [
            {
              key: "taskforce_Respiratory_2",
              dataType: "string",
              values: ["True"]
            }
          ]
        },
        tooltipConfig: {
          title: "Respiratory 2",
          defaultColumns: doctorTooltipColumns
        }
      },
      {
        name: "Respiratory Arise",
        deckLayerType: DeckLayerTypes.POINTS,
        layerType: LayerTypes.TARGET,
        layerColor: layerColors[34],
        dataUrl: "/poi-shape/filter",
        prop: ["Doctor"],
        tableColumns: doctorTable,
        filterSetGroups: doctorFS,
        barredDeepDiveAttributes: [],
        geographyAreaThreshold: 10000,
        defaultFilter: {
          poiDetails: [
            {
              key: "taskforce_Respiratory_Arise",
              dataType: "string",
              values: ["True"]
            }
          ]
        },
        tooltipConfig: {
          title: "Respiratory Arise",
          defaultColumns: doctorTooltipColumns
        }
      },
      {
        name: "Respiratory Breathefree",
        deckLayerType: DeckLayerTypes.POINTS,
        layerType: LayerTypes.TARGET,
        layerColor: layerColors[0],
        dataUrl: "/poi-shape/filter",
        prop: ["Doctor"],
        tableColumns: doctorTable,
        filterSetGroups: doctorFS,
        barredDeepDiveAttributes: [],
        geographyAreaThreshold: 10000,
        defaultFilter: {
          poiDetails: [
            {
              key: "taskforce_Respiratory_Breathefree",
              dataType: "string",
              values: ["True"]
            }
          ]
        },
        tooltipConfig: {
          title: "Respiratory Breathefree",
          defaultColumns: doctorTooltipColumns
        }
      },
      {
        name: "Respiratory Team 4",
        deckLayerType: DeckLayerTypes.POINTS,
        layerType: LayerTypes.TARGET,
        layerColor: layerColors[1],
        dataUrl: "/poi-shape/filter",
        prop: ["Doctor"],
        tableColumns: doctorTable,
        filterSetGroups: doctorFS,
        barredDeepDiveAttributes: [],
        geographyAreaThreshold: 10000,
        defaultFilter: {
          poiDetails: [
            {
              key: "taskforce_Respiratory_Team_4",
              dataType: "string",
              values: ["True"]
            }
          ]
        },
        tooltipConfig: {
          title: "Respiratory Team 4",
          defaultColumns: doctorTooltipColumns
        }
      },
      {
        name: "Respiratory Transformation and Special Projects",
        deckLayerType: DeckLayerTypes.POINTS,
        layerType: LayerTypes.TARGET,
        layerColor: layerColors[2],
        dataUrl: "/poi-shape/filter",
        prop: ["Doctor"],
        tableColumns: doctorTable,
        filterSetGroups: doctorFS,
        barredDeepDiveAttributes: [],
        geographyAreaThreshold: 10000,
        defaultFilter: {
          poiDetails: [
            {
              key: "taskforce_Respiratory_Transformation_and_Special_Projects",
              dataType: "string",
              values: ["True"]
            }
          ]
        },
        tooltipConfig: {
          title: "Respiratory Transformation and Special Projects",
          defaultColumns: doctorTooltipColumns
        }
      },
      {
        name: "STF",
        deckLayerType: DeckLayerTypes.POINTS,
        layerType: LayerTypes.TARGET,
        layerColor: layerColors[3],
        dataUrl: "/poi-shape/filter",
        prop: ["Doctor"],
        tableColumns: doctorTable,
        filterSetGroups: doctorFS,
        barredDeepDiveAttributes: [],
        geographyAreaThreshold: 10000,
        defaultFilter: {
          poiDetails: [
            {
              key: "taskforce_STF",
              dataType: "string",
              values: ["True"]
            }
          ]
        },
        tooltipConfig: {
          title: "STF",
          defaultColumns: doctorTooltipColumns
        }
      },
      {
        name: "Supracare 1",
        deckLayerType: DeckLayerTypes.POINTS,
        layerType: LayerTypes.TARGET,
        layerColor: layerColors[4],
        dataUrl: "/poi-shape/filter",
        prop: ["Doctor"],
        tableColumns: doctorTable,
        filterSetGroups: doctorFS,
        barredDeepDiveAttributes: [],
        geographyAreaThreshold: 10000,
        defaultFilter: {
          poiDetails: [
            {
              key: "taskforce_Supracare_1",
              dataType: "string",
              values: ["True"]
            }
          ]
        },
        tooltipConfig: {
          title: "Supracare 1",
          defaultColumns: doctorTooltipColumns
        }
      },
      {
        name: "Supracare 2",
        deckLayerType: DeckLayerTypes.POINTS,
        layerType: LayerTypes.TARGET,
        layerColor: layerColors[5],
        dataUrl: "/poi-shape/filter",
        prop: ["Doctor"],
        tableColumns: doctorTable,
        filterSetGroups: doctorFS,
        barredDeepDiveAttributes: [],
        geographyAreaThreshold: 10000,
        defaultFilter: {
          poiDetails: [
            {
              key: "taskforce_Supracare_2",
              dataType: "string",
              values: ["True"]
            }
          ]
        },
        tooltipConfig: {
          title: "Supracare 2",
          defaultColumns: doctorTooltipColumns
        }
      },
      {
        name: "Team 1",
        deckLayerType: DeckLayerTypes.POINTS,
        layerType: LayerTypes.TARGET,
        layerColor: layerColors[6],
        dataUrl: "/poi-shape/filter",
        prop: ["Doctor"],
        tableColumns: doctorTable,
        filterSetGroups: doctorFS,
        barredDeepDiveAttributes: [],
        geographyAreaThreshold: 10000,
        defaultFilter: {
          poiDetails: [
            {
              key: "taskforce_Team_1",
              dataType: "string",
              values: ["True"]
            }
          ]
        },
        tooltipConfig: {
          title: "Team 1",
          defaultColumns: doctorTooltipColumns
        }
      },
      {
        name: "Team 2",
        deckLayerType: DeckLayerTypes.POINTS,
        layerType: LayerTypes.TARGET,
        layerColor: layerColors[7],
        dataUrl: "/poi-shape/filter",
        prop: ["Doctor"],
        tableColumns: doctorTable,
        filterSetGroups: doctorFS,
        barredDeepDiveAttributes: [],
        geographyAreaThreshold: 10000,
        defaultFilter: {
          poiDetails: [
            {
              key: "taskforce_Team_2",
              dataType: "string",
              values: ["True"]
            }
          ]
        },
        tooltipConfig: {
          title: "Team 2",
          defaultColumns: doctorTooltipColumns
        }
      },
      {
        name: "Team 4 (OR)",
        deckLayerType: DeckLayerTypes.POINTS,
        layerType: LayerTypes.TARGET,
        layerColor: layerColors[8],
        dataUrl: "/poi-shape/filter",
        prop: ["Doctor"],
        tableColumns: doctorTable,
        filterSetGroups: doctorFS,
        barredDeepDiveAttributes: [],
        geographyAreaThreshold: 10000,
        defaultFilter: {
          poiDetails: [
            {
              key: "taskforce_Team_4_(OR)",
              dataType: "string",
              values: ["True"]
            }
          ]
        },
        tooltipConfig: {
          title: "Team 4 (OR)",
          defaultColumns: doctorTooltipColumns
        }
      },
      {
        name: "Team Hybrid",
        deckLayerType: DeckLayerTypes.POINTS,
        layerType: LayerTypes.TARGET,
        layerColor: layerColors[9],
        dataUrl: "/poi-shape/filter",
        prop: ["Doctor"],
        tableColumns: doctorTable,
        filterSetGroups: doctorFS,
        barredDeepDiveAttributes: [],
        geographyAreaThreshold: 10000,
        defaultFilter: {
          poiDetails: [
            {
              key: "taskforce_Team_Hybrid",
              dataType: "string",
              values: ["True"]
            }
          ]
        },
        tooltipConfig: {
          title: "Team Hybrid",
          defaultColumns: doctorTooltipColumns
        }
      },
      {
        name: "Urology1",
        deckLayerType: DeckLayerTypes.POINTS,
        layerType: LayerTypes.TARGET,
        layerColor: layerColors[10],
        dataUrl: "/poi-shape/filter",
        prop: ["Doctor"],
        tableColumns: doctorTable,
        filterSetGroups: doctorFS,
        barredDeepDiveAttributes: [],
        geographyAreaThreshold: 10000,
        defaultFilter: {
          poiDetails: [
            {
              key: "taskforce_Urology1",
              dataType: "string",
              values: ["True"]
            }
          ]
        },
        tooltipConfig: {
          title: "Urology1",
          defaultColumns: doctorTooltipColumns
        }
      },
      {
        name: "Urology2",
        deckLayerType: DeckLayerTypes.POINTS,
        layerType: LayerTypes.TARGET,
        layerColor: layerColors[11],
        dataUrl: "/poi-shape/filter",
        prop: ["Doctor"],
        tableColumns: doctorTable,
        filterSetGroups: doctorFS,
        barredDeepDiveAttributes: [],
        geographyAreaThreshold: 10000,
        defaultFilter: {
          poiDetails: [
            {
              key: "taskforce_Urology2",
              dataType: "string",
              values: ["True"]
            }
          ]
        },
        tooltipConfig: {
          title: "Urology2",
          defaultColumns: doctorTooltipColumns
        }
      },
      {
        name: "Urology3",
        deckLayerType: DeckLayerTypes.POINTS,
        layerType: LayerTypes.TARGET,
        layerColor: layerColors[12],
        dataUrl: "/poi-shape/filter",
        prop: ["Doctor"],
        tableColumns: doctorTable,
        filterSetGroups: doctorFS,
        barredDeepDiveAttributes: [],
        geographyAreaThreshold: 10000,
        defaultFilter: {
          poiDetails: [
            {
              key: "taskforce_Urology3",
              dataType: "string",
              values: ["True"]
            }
          ]
        },
        tooltipConfig: {
          title: "Urology3",
          defaultColumns: doctorTooltipColumns
        }
      },
      {
        name: "Urology team 2",
        deckLayerType: DeckLayerTypes.POINTS,
        layerType: LayerTypes.TARGET,
        layerColor: layerColors[13],
        dataUrl: "/poi-shape/filter",
        prop: ["Doctor"],
        tableColumns: doctorTable,
        filterSetGroups: doctorFS,
        barredDeepDiveAttributes: [],
        geographyAreaThreshold: 10000,
        defaultFilter: {
          poiDetails: [
            {
              key: "taskforce_Urology_team_2",
              dataType: "string",
              values: ["True"]
            }
          ]
        },
        tooltipConfig: {
          title: "Urology team 2",
          defaultColumns: doctorTooltipColumns
        }
      },
      {
        name: "Virology",
        deckLayerType: DeckLayerTypes.POINTS,
        layerType: LayerTypes.TARGET,
        layerColor: layerColors[14],
        dataUrl: "/poi-shape/filter",
        prop: ["Doctor"],
        tableColumns: doctorTable,
        filterSetGroups: doctorFS,
        barredDeepDiveAttributes: [],
        geographyAreaThreshold: 10000,
        defaultFilter: {
          poiDetails: [
            {
              key: "taskforce_Virology",
              dataType: "string",
              values: ["True"]
            }
          ]
        },
        tooltipConfig: {
          title: "Virology",
          defaultColumns: doctorTooltipColumns
        }
      },
      {
        name: "Vitalis 1",
        deckLayerType: DeckLayerTypes.POINTS,
        layerType: LayerTypes.TARGET,
        layerColor: layerColors[15],
        dataUrl: "/poi-shape/filter",
        prop: ["Doctor"],
        tableColumns: doctorTable,
        filterSetGroups: doctorFS,
        barredDeepDiveAttributes: [],
        geographyAreaThreshold: 10000,
        defaultFilter: {
          poiDetails: [
            {
              key: "taskforce_Vitalis_1",
              dataType: "string",
              values: ["True"]
            }
          ]
        },
        tooltipConfig: {
          title: "Vitalis 1",
          defaultColumns: doctorTooltipColumns
        }
      },
      {
        name: "Vitalis 2",
        deckLayerType: DeckLayerTypes.POINTS,
        layerType: LayerTypes.TARGET,
        layerColor: layerColors[16],
        dataUrl: "/poi-shape/filter",
        prop: ["Doctor"],
        tableColumns: doctorTable,
        filterSetGroups: doctorFS,
        barredDeepDiveAttributes: [],
        geographyAreaThreshold: 10000,
        defaultFilter: {
          poiDetails: [
            {
              key: "taskforce_Vitalis_2",
              dataType: "string",
              values: ["True"]
            }
          ]
        },
        tooltipConfig: {
          title: "Vitalis 2",
          defaultColumns: doctorTooltipColumns
        }
      },
      {
        name: "Vitalis 3",
        deckLayerType: DeckLayerTypes.POINTS,
        layerType: LayerTypes.TARGET,
        layerColor: layerColors[17],
        dataUrl: "/poi-shape/filter",
        prop: ["Doctor"],
        tableColumns: doctorTable,
        filterSetGroups: doctorFS,
        barredDeepDiveAttributes: [],
        geographyAreaThreshold: 10000,
        defaultFilter: {
          poiDetails: [
            {
              key: "taskforce_Vitalis_3",
              dataType: "string",
              values: ["True"]
            }
          ]
        },
        tooltipConfig: {
          title: "Vitalis 3",
          defaultColumns: doctorTooltipColumns
        }
      },
      {
        name: "Vitalis 4",
        deckLayerType: DeckLayerTypes.POINTS,
        layerType: LayerTypes.TARGET,
        layerColor: layerColors[18],
        dataUrl: "/poi-shape/filter",
        prop: ["Doctor"],
        tableColumns: doctorTable,
        filterSetGroups: doctorFS,
        barredDeepDiveAttributes: [],
        geographyAreaThreshold: 10000,
        defaultFilter: {
          poiDetails: [
            {
              key: "taskforce_Vitalis_4",
              dataType: "string",
              values: ["True"]
            }
          ]
        },
        tooltipConfig: {
          title: "Vitalis 4",
          defaultColumns: doctorTooltipColumns
        }
      },
      {
        name: "Vitalis 5",
        deckLayerType: DeckLayerTypes.POINTS,
        layerType: LayerTypes.TARGET,
        layerColor: layerColors[19],
        dataUrl: "/poi-shape/filter",
        prop: ["Doctor"],
        tableColumns: doctorTable,
        filterSetGroups: doctorFS,
        barredDeepDiveAttributes: [],
        geographyAreaThreshold: 10000,
        defaultFilter: {
          poiDetails: [
            {
              key: "taskforce_Vitalis_5",
              dataType: "string",
              values: ["True"]
            }
          ]
        },
        tooltipConfig: {
          title: "Vitalis 5",
          defaultColumns: doctorTooltipColumns
        }
      },
      {
        name: "Vitalis Care",
        deckLayerType: DeckLayerTypes.POINTS,
        layerType: LayerTypes.TARGET,
        layerColor: layerColors[20],
        dataUrl: "/poi-shape/filter",
        prop: ["Doctor"],
        tableColumns: doctorTable,
        filterSetGroups: doctorFS,
        barredDeepDiveAttributes: [],
        geographyAreaThreshold: 10000,
        defaultFilter: {
          poiDetails: [
            {
              key: "taskforce_Vitalis_Care",
              dataType: "string",
              values: ["True"]
            }
          ]
        },
        tooltipConfig: {
          title: "Vitalis Care",
          defaultColumns: doctorTooltipColumns
        }
      },
      {
        name: "Vitalis Integra",
        deckLayerType: DeckLayerTypes.POINTS,
        layerType: LayerTypes.TARGET,
        layerColor: layerColors[21],
        dataUrl: "/poi-shape/filter",
        prop: ["Doctor"],
        tableColumns: doctorTable,
        filterSetGroups: doctorFS,
        barredDeepDiveAttributes: [],
        geographyAreaThreshold: 10000,
        defaultFilter: {
          poiDetails: [
            {
              key: "taskforce_Vitalis_Integra",
              dataType: "string",
              values: ["True"]
            }
          ]
        },
        tooltipConfig: {
          title: "Vitalis Integra",
          defaultColumns: doctorTooltipColumns
        }
      },
      {
        name: "Vitalis Spartan",
        deckLayerType: DeckLayerTypes.POINTS,
        layerType: LayerTypes.TARGET,
        layerColor: layerColors[22],
        dataUrl: "/poi-shape/filter",
        prop: ["Doctor"],
        tableColumns: doctorTable,
        filterSetGroups: doctorFS,
        barredDeepDiveAttributes: [],
        geographyAreaThreshold: 10000,
        defaultFilter: {
          poiDetails: [
            {
              key: "taskforce_Vitalis_Spartan",
              dataType: "string",
              values: ["True"]
            }
          ]
        },
        tooltipConfig: {
          title: "Vitalis Spartan",
          defaultColumns: doctorTooltipColumns
        }
      },
      {
        name: "WOMENS HEALTH",
        deckLayerType: DeckLayerTypes.POINTS,
        layerType: LayerTypes.TARGET,
        layerColor: layerColors[23],
        dataUrl: "/poi-shape/filter",
        prop: ["Doctor"],
        tableColumns: doctorTable,
        filterSetGroups: doctorFS,
        barredDeepDiveAttributes: [],
        geographyAreaThreshold: 10000,
        defaultFilter: {
          poiDetails: [
            {
              key: "taskforce_WOMENS_HEALTH",
              dataType: "string",
              values: ["True"]
            }
          ]
        },
        tooltipConfig: {
          title: "WOMENS HEALTH",
          defaultColumns: doctorTooltipColumns
        }
      },
      {
        name: "XC",
        deckLayerType: DeckLayerTypes.POINTS,
        layerType: LayerTypes.TARGET,
        layerColor: layerColors[24],
        dataUrl: "/poi-shape/filter",
        prop: ["Doctor"],
        tableColumns: doctorTable,
        filterSetGroups: doctorFS,
        barredDeepDiveAttributes: [],
        geographyAreaThreshold: 10000,
        defaultFilter: {
          poiDetails: [
            {
              key: "taskforce_XC",
              dataType: "string",
              values: ["True"]
            }
          ]
        },
        tooltipConfig: {
          title: "XC",
          defaultColumns: doctorTooltipColumns
        }
      },
      {
        name: "Xterna 1",
        deckLayerType: DeckLayerTypes.POINTS,
        layerType: LayerTypes.TARGET,
        layerColor: layerColors[25],
        dataUrl: "/poi-shape/filter",
        prop: ["Doctor"],
        tableColumns: doctorTable,
        filterSetGroups: doctorFS,
        barredDeepDiveAttributes: [],
        geographyAreaThreshold: 10000,
        defaultFilter: {
          poiDetails: [
            {
              key: "taskforce_Xterna_1",
              dataType: "string",
              values: ["True"]
            }
          ]
        },
        tooltipConfig: {
          title: "Xterna 1",
          defaultColumns: doctorTooltipColumns
        }
      },
      {
        name: "Xterna 2",
        deckLayerType: DeckLayerTypes.POINTS,
        layerType: LayerTypes.TARGET,
        layerColor: layerColors[26],
        dataUrl: "/poi-shape/filter",
        prop: ["Doctor"],
        tableColumns: doctorTable,
        filterSetGroups: doctorFS,
        barredDeepDiveAttributes: [],
        geographyAreaThreshold: 10000,
        defaultFilter: {
          poiDetails: [
            {
              key: "taskforce_Xterna_2",
              dataType: "string",
              values: ["True"]
            }
          ]
        },
        tooltipConfig: {
          title: "Xterna 2",
          defaultColumns: doctorTooltipColumns
        }
      },
      {
        name: "Xterna 3",
        deckLayerType: DeckLayerTypes.POINTS,
        layerType: LayerTypes.TARGET,
        layerColor: layerColors[27],
        dataUrl: "/poi-shape/filter",
        prop: ["Doctor"],
        tableColumns: doctorTable,
        filterSetGroups: doctorFS,
        barredDeepDiveAttributes: [],
        geographyAreaThreshold: 10000,
        defaultFilter: {
          poiDetails: [
            {
              key: "taskforce_Xterna_3",
              dataType: "string",
              values: ["True"]
            }
          ]
        },
        tooltipConfig: {
          title: "Xterna 3",
          defaultColumns: doctorTooltipColumns
        }
      },
      {
        name: "Zesta",
        deckLayerType: DeckLayerTypes.POINTS,
        layerType: LayerTypes.TARGET,
        layerColor: layerColors[28],
        dataUrl: "/poi-shape/filter",
        prop: ["Doctor"],
        tableColumns: doctorTable,
        filterSetGroups: doctorFS,
        barredDeepDiveAttributes: [],
        geographyAreaThreshold: 10000,
        defaultFilter: {
          poiDetails: [
            {
              key: "taskforce_Zesta",
              dataType: "string",
              values: ["True"]
            }
          ]
        },
        tooltipConfig: {
          title: "Zesta",
          defaultColumns: doctorTooltipColumns
        }
      },
      {
        name: "Zion 1",
        deckLayerType: DeckLayerTypes.POINTS,
        layerType: LayerTypes.TARGET,
        layerColor: layerColors[29],
        dataUrl: "/poi-shape/filter",
        prop: ["Doctor"],
        tableColumns: doctorTable,
        filterSetGroups: doctorFS,
        barredDeepDiveAttributes: [],
        geographyAreaThreshold: 10000,
        defaultFilter: {
          poiDetails: [
            {
              key: "taskforce_Zion_1",
              dataType: "string",
              values: ["True"]
            }
          ]
        },
        tooltipConfig: {
          title: "Zion 1",
          defaultColumns: doctorTooltipColumns
        }
      },
      {
        name: "Zion 2",
        deckLayerType: DeckLayerTypes.POINTS,
        layerType: LayerTypes.TARGET,
        layerColor: layerColors[30],
        dataUrl: "/poi-shape/filter",
        prop: ["Doctor"],
        tableColumns: doctorTable,
        filterSetGroups: doctorFS,
        barredDeepDiveAttributes: [],
        geographyAreaThreshold: 10000,
        defaultFilter: {
          poiDetails: [
            {
              key: "taskforce_Zion_2",
              dataType: "string",
              values: ["True"]
            }
          ]
        },
        tooltipConfig: {
          title: "Zion 2",
          defaultColumns: doctorTooltipColumns
        }
      },
      {
        name: "Zion Hybrid",
        deckLayerType: DeckLayerTypes.POINTS,
        layerType: LayerTypes.TARGET,
        layerColor: layerColors[31],
        dataUrl: "/poi-shape/filter",
        prop: ["Doctor"],
        tableColumns: doctorTable,
        filterSetGroups: doctorFS,
        barredDeepDiveAttributes: [],
        geographyAreaThreshold: 10000,
        defaultFilter: {
          poiDetails: [
            {
              key: "taskforce_Zion_Hybrid",
              dataType: "string",
              values: ["True"]
            }
          ]
        },
        tooltipConfig: {
          title: "Zion Hybrid",
          defaultColumns: doctorTooltipColumns
        }
      }
    ]
  }
];

const designationUserRoleMapping: UserRoleMapping[] = [
  {
    designation: "Feet On Street",
    roles: ["basic", "maps_user"],
    info: "A Feet On Street is a Mobile user and will not have access to the DS Maps App"
  },
  {
    designation: "Branch Manager",
    roles: ["basic", "insights_user", "team_admin", UserRole.MONITORING_EXPORT],
    info: "Bla bla Bla"
  },
  {
    designation: "Country Head",
    roles: ["basic", "insights_user", "client_admin", UserRole.MONITORING_EXPORT],
    info: "Bla bla Bla"
  }
];

const enabledModules = [
  DashboardUrl.admin,
  DashboardUrl.explore
  // DashboardUrl.userManagment,
  // DashboardUrl.monitoring
];

export const ciplaConfig: PlatformConfig = {
  advancedDeepDive: false,
  apiUrl: "https://cipla.stg.datasutram.com/api",
  barredPOIs: [],
  baseUrl: "https://cipla.stg.datasutram.com",
  defaultFilterSetConfig: defaultFS,
  defaultGeographyAreaThreshold: null,
  defaultTableConfig: defaultTable,
  elasticUrl: "https://cipla.stg.datasutram.com/api/elastic-search",
  enableAdvancedSearch: true,
  enableDSMapsDashboard: true,
  enableGeographyMultiSelect: false,
  enableKCLoginRedirect: true,
  mapboxBaseMapStyle: "dark",
  enableRaiseAnIssue: false,
  enableRankByFeature: false,
  enableRegistration: false,
  enableShowValuesForFeature: false,
  enableDashboardModules: enabledModules,
  defaultModule: DashboardUrl.explore,
  hasClusters: false,
  layerSetConfig: layerSetsConfig,
  layerObjectsSelectionLimit: 20000,
  poiRelationshipConfig: "poi-shape",
  designationUserRoleMapping: designationUserRoleMapping
};

export const ciplaMonitoringConfig: MonitoringConfig = {
  topUserId: 8,
  topUserTeamIds: [10],
  defaultStartDate: "2023-05-01",
  defaultEndDate: "",
  opportunityTappedTable: [],
  opportunityTappedTableHistory: []
};
